// Color system

// scss-docs-start gray-color-variables
$white: rgb(255 255 255) !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #1e1e21 !default;
$black: #000 !default;

// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
) !default;

// scss-docs-end gray-colors-map
// fusv-enable

$blue: #084baf;
$indigo: #6610f2;
$purple: #a41357;
$pink: #e83e8c;
$red: #ef4317;
$orange: #cf4520;
$yellow: #ca312b;
$green: #1d8621;
$teal: #20c997;
$cyan: #000c59;
$gray-1: #f5f5f5;

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
) !default;

// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $blue !default;
$secondary: $gray-1 !default;
$success: $yellow !default;
$info: $cyan !default;
$warning: $orange !default;
$danger: $red !default;
$light: $gray-200 !default;
$dark: $gray-800 !default;

// scss-docs-end theme-color-variables
