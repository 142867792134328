﻿a:hover {
  color: var(--bs-warning);
}

a:active {
  color: var(--bs-warning);
}

.btn, button, .page-link {
  font-family: MuseoSans300, Museo Sans, -apple-system-body,
  -apple-system, BlinkMacSystemFont, Segoe UI, Liberation Sans, sans-serif;
}

.page-header {
  background-color: #e4e6f4;
  box-shadow: inset 0 0.5rem 1rem -0.5rem rgba(0, 0, 0, 0.4);
}

/* Focus Customization */
a:focus, .post-image-thumbnail:focus-within, .carousel-inner:focus-within {
  border-color: var(--bs-info);
  border-style: dashed;
  outline: 0;
}

.social-media .social-badge:focus {
  border-radius: 50%;
}

.social-media .lg-social-badge:focus {
  border: none;
}

.social-media .lg-social-badge:focus > i, .social-media .lg-social-badge:focus > span {
  border-color: var(--bs-info);
  border-style: dashed;
  border-radius: 50%;
}

a.focused-text:focus, .post-copy a:focus {
  padding: 5px;
  border-radius: var(--bs-border-radius);
}

.post-image-thumbnail:focus-within, .carousel-inner:focus-within {
  border-radius: var(--bs-border-radius-xl);
}

.page-header {
  background-color: #e4e6f4;
  box-shadow: inset 0 0.5rem 1rem -0.5rem rgba(0, 0, 0, 0.4);
}

/* CARDS */
.card {
  border-radius: var(--bs-border-radius-xl);
}

/* NAV */
.navbar-brand:focus {
  border: none;
}

.navbar-brand img {
  border: 3px solid transparent;
  margin-left: -3px;
  margin-top: 14px;
  border-radius: var(--bs-border-radius);;
}

.navbar-brand:focus img {
  border-color: var(--bs-info);
  border-style: dashed;
  border-width: 3px;
  outline: 0;
  border-radius: var(--bs-border-radius);;
}

.site-title a,
.site-description {
  color: var(--bs-primary);
}

.nav-link {
  /* transition: all 0.15s ease-in-out; */
  padding-bottom: 0;
  margin-bottom: 8px;
}

.nav-underline {
  gap: 2rem !important;
}

.nav-underline .nav-link {
  padding: 6px 6px 8px 6px;
}

.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 500;
  border-bottom-color: var(--bs-danger);
  border-width: 3px;
  margin-bottom: 0;
}

.nav-underline .nav-link:hover {
  color: var(--bs-primary);
  border-bottom-color: var(--bs-danger);
  padding-bottom: 3px 5px 8px 5px;
  border-width: 3px;
  margin-bottom: 0;
  outline: 0;
}

.nav-underline .nav-link:focus {
  color: var(--bs-primary);
  border-bottom-color: var(--bs-danger);
  padding: 3px 5px 8px 5px;
  border-width: 3px;
  margin-bottom: 0;
  border-top-color: var(--bs-info);
  border-top-style: dashed;
  border-left-color: var(--bs-info);
  border-left-style: dashed;
  border-right-color: var(--bs-info);
  border-right-style: dashed;
  outline: 0;
}

.nav-underline .nav-link:focus-visible {
  box-shadow: none;
  outline: 0;
}

.nav-underline #submenu li a:focus {
  border:none;
  color: var(--bs-primary);
}

.nav-underline #submenu li:focus-within {
  background-color: #fff;
  box-shadow: inset 0 6px 5px #00000040;
}

.dropdown-menu,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  margin-top: 16px !important;
  background-color: #003087;
  border-radius: 0 0 var(--bs-border-radius-xl) var(--bs-border-radius-xl);
  box-shadow: inset 0px 6px 5px 0px rgba(0, 0, 0, 0.25);
}

.dropdown-menu .dropdown-item:hover {
  box-shadow: inset 0px 6px 5px 0px rgba(0, 0, 0, 0.25);
}

.dropdown-menu .dropdown-item:hover a {
  color: var(--bs-primary);
}

.dropdown-menu .dropdown-item a {
  text-decoration: none;
}

.dropdown-menu > li > a {
  padding: 18px 20px;
  color: #fff;
}

/* CAROUSEL */
.carousel .carousel-indicators {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 15px;
}

.carousel {
  background-color: var(--bs-info-rgb);
}

.carousel-inner {
  border: 3px solid transparent;
}

.carousel-control-prev,
.carousel-control-next {
  border: 3px solid transparent;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover, .carousel-control-next:focus {
  background-color: rgba(0, 0, 0, 0.4);
}

.carousel .carousel-indicators [data-bs-target] {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin-left: 6px;
  margin-right: 5px;
  overflow: hidden;
  background-color: #fff;
  opacity: 1;
}

.carousel .carousel-indicators [data-bs-target]:focus-visible {
  outline: 0;
}

.carousel .carousel-indicators [data-bs-target]:hover {
  background-color: var(--bs-info);
  color: #fff;
}

.carousel .carousel-indicators [data-bs-target]:focus {
  background-color: var(--bs-primary);
  color: #fff;
}

.carousel .carousel-indicators .text[data-bs-target] {
  width: 20px;
  height: 20px;
  text-indent: 0;
  font-size: 14px;
  line-height: 1.1;
}

.carousel .carousel-indicators .text.active[data-bs-target] {
  background-color: var(--bs-warning);
  color: #fff;
}

.carousel .carousel-indicators .text.active[data-bs-target]:focus {
  background-color: var(--bs-info);
  color: #fff;
}

.carousel-caption {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  right: 18%;
  left: 18%;
  bottom: 1.75rem;
  padding-bottom: 30px !important;
  text-align: left;
}

#carousel-play-btn {
  position: absolute;
  left: 20px;
  top: 30px;
  z-index: 500;
}

#carousel-play-btn:focus-visible {
  box-shadow: 0 0 0 4px rgba(0,0,0,1) !important;
}

.site-branding {
  padding-left: 0;
}

/* Fix for video - assumes carousel images are 426px tall */
.embed-responsive.embed-responsive-16by9 {
  padding-bottom: 42.6%;
  max-height: 426px;
}

/*==== Social Icons ====*/
#social {
  top: 0;
  float: none;
}

.social-profile {
  margin: 3px;
}

/* ===== Retail page event banner styles ===== */
.events-banner-container {
  float: left;
  position: relative;
  height: 140px;
  background-color: #b53415;
  width: 100%;
  background-image: url("../img/events-banner.jpg");
  background-repeat: no-repeat;
}

.events-banner-title {
  width: 50%;
  text-align: right;
  position: absolute;
  top: 0;
  right: 20px;
  height: 100%;
  z-index: 10000;
}

.events-banner-title h2 {
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0;
  text-shadow: 0px 2px 9px rgba(0,0,0,0.53);
}

.events-banner-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 530px;
}

.events-banner-list {
  background-color: var(--bs-light);
  text-align: center;
  padding: 15px 10px 10px;
  float: left;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.events-banner-list ul {
  color: #444444;
  list-style: none;
  -webkit-margin-before: 0;
  -webkit-padding-start: 0;
}

@-moz-document url-prefix() {
  .events-banner-list ul {
    color: #444444;
    list-style: none;
    -webkit-margin-before: 0;
    -webkit-padding-start: 0;
    margin-left: -35px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .events-banner-list ul {
    color: #444444;
    list-style: none;
    -webkit-margin-before: 0;
    -webkit-padding-start: 0;
    margin-left: -35px;
  }
}

@media screen\0 {
  .events-banner-list ul {
    color: #444444;
    list-style: none;
    -webkit-margin-before: 0;
    -webkit-padding-start: 0;
    margin-left: -35px;
  }
}

.events-banner-list li {
  font: normal 1.1538461538461537em/1.7em Arial, sans-serif;
}

/* Social Media Icons */

.fa-stack {
  height: 2.2em !important;
  width: 2.2em !important;
}

.instagram-icon {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background-clip: text;
  color: rgba(0, 0, 0, 0);
  -webkit-background-clip: text;
}

span.insta-hover-inner-shadow:hover > i.instagram-shadow {
  text-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.5);
}

span.insta-hover-outer-shadow:hover > i.instagram-shadow {
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .instagram-icon {
    background: none;
    color: #f3b03c;
  }
}

.youtube-icon {
  color: #ff0f17;
}

.facebook-icon {
  color: #4267b2;
}

.linkedin-icon {
  color: #0073b1;
}

.pinterest-icon {
  color: #e60c23;
}

.twitter-icon {
  color: #000;
}

ul.social-media li.col {
  float: left;
}

ul.social-media li.col-6 {
  width: 20%;
}

.social-media {
  font-size: 30px;
}

.social-media {
  display: inline-block;
  padding-top: 0 !important;
  padding-left: 0;
}

.social-media li {
  list-style: none;
  padding: 0 10px;
}

.single-hover-outer-shadow:hover {
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

span.hover-outer-shadow:hover > i.fa-stack-2x::before {
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

span.hover-inner-shadow:hover > i.fa-stack-1x::before {
  background-color: rgba(0, 0, 0, 0.5);
  color: transparent;
  text-shadow: 0px 0px 1px rgba(var(--bs-secondary-rgb), 1);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

.copyright {
  color: #565a60;
}

.site-info a {
  color: #565a60;
}

.social-profile {
  color: #1e73be;
}

/* TABLES */
.dataTables_wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
    padding-right: 30px;
}

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
    outline: none;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    cursor: pointer;
    position: relative;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    position: absolute;
    bottom: 0.9em;
    display: block;
    opacity: 0.3;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:before {
  top: 8px;
  right: 45px;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  margin-left: 5px;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0de";
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc_disabled:after {
  top: 8px;
  right: 45px;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  margin-left: 5px;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0dd";
}

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
    opacity: 1;
}

table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    opacity: 0;
}

.pointer {
  cursor: pointer !important;
}

/* Tables */
.sortable:hover, .pointer:hover {
  background-color: var(--bs-light);
}

.sortable:active, .pointer:active {
  background-color: rgba(var(--bs-primary-rgb), .2);
}

.job-search {
  padding: 12px 6px;
}

.job-search-container {
  margin-left: 0;
  margin-right: 0;
}

.job-type-table-text {
  color: var(--bs-warning) !important;
  font-weight: bold;
}

.min-width-cell {
  min-width: 125px;
}

.min-width-cell-sm {
  min-width: 75px;
}

div.dataTables_paginate {
  padding-top: 20px;
  display: flex;
  flex-direction: row-reverse !important;
}

/* Tom Select CSS */
div.job-search > select.form-control:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  right: calc(0.75rem + 5px);
  margin-top: -3px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #343a40 transparent transparent transparent;
}

/* VIDEOS */
.youtube-video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.youtube-video-container::after {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.youtube-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Widgets */
p.job-info, li.job-info {
  font-size: 14px;
}