/* FONTS */

$font-family-headlines: MuseoSans700, Museo Sans, -apple-system-body,
  -apple-system, BlinkMacSystemFont, Segoe UI, Liberation Sans, sans-serif;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-headlines;
}

p.site-description {
  font-family: MuseoSans500, Museo Sans, -apple-system-body,
  -apple-system, BlinkMacSystemFont, Segoe UI, Liberation Sans, sans-serif;
  margin-top: 4px;
  margin-bottom: 0;
  margin-left: 15px;
  display: inline-block;
}

/* NAV */
.nav-item {
  font-family: MuseoSans500, Museo Sans, -apple-system-body,
  -apple-system, BlinkMacSystemFont, Segoe UI, Liberation Sans, sans-serif;
  font-size: 1rem;
}