/* hl-careers theme 2.0
*/

// COLORS
@import "custom-theme-colors";

// base font size override
// $font-size-base: 0.875rem;

// Required Imports
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

$enable-dark-mode: false;

// --- VARIABLE CUSTOMIZATION ---
@import "custom-variables";

// More Required Imports
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

// IMPORT CUSTOM SCSS STUBS
// @import"custom-mixins";

// This is where you put any customization not related to functions and variables so that we don't have to edit the core Bootstrap files. This way, we can easily import updated core files from Twitter without breaking our themes.
// ------------------------------------------------------------------------------
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
// @import "../../node_modules/bootstrap/scss/accordion";
// @import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
// @import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
// @import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/offcanvas";
// @import "../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";

// scss-docs-end import-stack

// This is where you put any customization not related to functions and variables
// ------------------------------------------------------------------------------

// @import "custom-alerts";
// @import "custom-background";
// @import "custom-cards";
// @import "custom-type";
// @import "custom-buttons-links";
// @import "custom-nav";
// @import "custom-formatting";
// @import "custom-forms";
// @import "custom-switches";
// @import "submenus";
// @import "custom-progress-crumbs";
// @import "hl-responsive-tables";
